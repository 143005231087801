import Vue from 'vue';
import App from '@/App.vue';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import router from '@/plugins/router';
import store from '@/store';
import { groupByKey } from '@/helpers/array.helper';

/*https://vue-select.org/*/
import vSelect from 'vue-select';

/* https://github.com/thecodealer/vue-panzoom */
/* wrapper of https://github.com/anvaka/panzoom */
import panZoom from 'vue-panzoom';

// Axios.
import axios from 'axios';
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest'
};
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
      if (error.response.status === 401) {
        return window.location.replace(`/login?returnUrl=${encodeURIComponent(router.history.current.fullPath)}`);
    }
    if (error.response.status === 403) {
      return alert('Du saknar åtkomst');
    }
    throw error;
  }
);
// ./ Axios.

// Vue2TouchEvents - https://github.com/jerrybendy/vue-touch-events
import Vue2TouchEvents from 'vue2-touch-events';

// VueSidebarMenu - https://github.com/yaminncco/vue-sidebar-menu
import VueSidebarMenu from 'vue-sidebar-menu';
// ./ VueSidebarMenu.

// FA - https://github.com/FortAwesome/vue-fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library, config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import {
  faUser,
  faUsers,
  faClipboardList,
  faHome,
  faArrowsAltH,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFire,
  faSignOutAlt,
  faSpinner,
  faPenSquare,
  faInfoCircle,
  faImage,
  faFolderOpen,
  faFileEdit,
  faLink,
  faExternalLink,
  faPlusSquare,
  faRegistered,
  faTrashAlt,
  faCog,
  faAddressBook,
  faBoxOpen,
  faSitemap,
  faBug,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faFileArchive,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileAlt,
  faFile,
  faList,
  faMap,
  faMapMarkedAlt,
  faMapMarkerPlus,
  faMapMarkerExclamation,
  faSearchMinus,
  faSearchPlus,
  faBuilding,
  faTachometerAlt,
  faSquare,
  faCheckSquare,
  faExclamationTriangle,
  faArrowAltCircleLeft,
  faClipboardCheck,
  faCheckDouble,
  faCommentLines,
  faCommentPlus,
  faPrint,
  faBars,
  faFilter,
  faFireExtinguisher,
  faSensorFire,
  faFireSmoke,
  faBlanket,
  faPaperclip,
  faFolder,
  faFolders,
  faCircle,
  faTimes,
  faEnvelope,
    faCalendarTimes,
    faCalendar,
  faUserHardHat,
  faToggleOff,
  faToggleOn,
  faEye,
  faSignalSlash,
  faLock,
  faKey,
    faPlayCircle,
    faTachometerFast,
    faChartLine,
    faChartNetwork,
    faTag,
    faGripVertical,
} from '@fortawesome/pro-solid-svg-icons';
library.add(
  faUser,
  faUsers,
  faClipboardList,
  faHome,
  faArrowsAltH,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFire,
  faSignOutAlt,
  faSpinner,
  faPenSquare,
  faInfoCircle,
  faImage,
  faFolderOpen,
  faFileEdit,
  faLink,
  faExternalLink,
  faPlusSquare,
  faRegistered,
  faTrashAlt,
  faCog,
  faAddressBook,
  faBoxOpen,
  faSitemap,
  faBug,
  faFilePdf,
  faFileImage,
  faFileVideo,
  faFileArchive,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faFileAlt,
  faFile,
  faList,
  faMap,
  faMapMarkedAlt,
  faMapMarkerPlus,
  faMapMarkerExclamation,
  faSearchMinus,
  faSearchPlus,
  faBuilding,
  faTachometerAlt,
  faSquare,
  faCheckSquare,
  faExclamationTriangle,
  faArrowAltCircleLeft,
  faClipboardCheck,
  faCheckDouble,
  faCommentLines,
  faCommentPlus,
  faPrint,
  faBars,
  faFilter,
  faFireExtinguisher,
  faSensorFire,
  faFireSmoke,
  faBlanket,
  faPaperclip,
  faFolder,
  faFolders,
  faCircle,
  faTimes,
  faEnvelope,
    faCalendarTimes,
    faCalendar,
  faUserHardHat,
  faToggleOff,
  faToggleOn,
  faEye,
  faSignalSlash,
  faLock,
  faPlayCircle,
    faKey,
    faTachometerFast,
    faChartLine,
    faChartNetwork,
    faTag,
    faGripVertical,
    
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
// ./ FA.

/*Filters*/
import '@/filters/datetime.filters';
import '@/filters/file.filters';
import '@/filters/app.filters';

// SW.
import '@/plugins/registerServiceWorker';

Vue.config.productionTip = true;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueSidebarMenu);
Vue.use(panZoom);
Vue.use(Vue2TouchEvents);
Vue.component('v-select', vSelect);

/* Globals */
const eventHub = new Vue();
Vue.prototype.$eventHub = eventHub;
Array.prototype.groupByKey = function (key) {
    return groupByKey(this, key);
};

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
